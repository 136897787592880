import axios from 'axios';
import { DIRECTUS_INSTANCE } from '../api';

class AuthService {
  constructor() {
    this.TOKEN_KEY = 'directus_token';
    this.TOKEN_EXPIRY_KEY = 'directus_token_expiry';
    this.REFRESH_THRESHOLD = 5 * 60; // 5 minutes in seconds
  }

  getToken() {
    return localStorage.getItem(this.TOKEN_KEY) || sessionStorage.getItem(this.TOKEN_KEY);
  }

  getTokenExpiry() {
    return localStorage.getItem(this.TOKEN_EXPIRY_KEY);
  }

  setToken(token, expiry) {
    localStorage.setItem(this.TOKEN_KEY, token);
    if (expiry) {
      localStorage.setItem(this.TOKEN_EXPIRY_KEY, expiry);
    }
  }

  removeToken() {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.TOKEN_EXPIRY_KEY);
    sessionStorage.removeItem(this.TOKEN_KEY);
  }

  isTokenExpired() {
    const expiry = this.getTokenExpiry();
    if (!expiry) return true;
    
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime >= expiry - this.REFRESH_THRESHOLD;
  }

  async refreshToken() {
    try {
      const token = this.getToken();
      if (!token) return false;

      const response = await axios.post(`${DIRECTUS_INSTANCE}/auth/refresh`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.data && response.data.data.access_token) {
        // Decode token to get expiry time
        const jwtPayload = JSON.parse(atob(response.data.data.access_token.split('.')[1]));
        const tokenExpiry = jwtPayload.exp;

        this.setToken(response.data.data.access_token, tokenExpiry);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Token refresh failed:', error);
      return false;
    }
  }

  async login(email, password) {
    try {
      const response = await axios.post(`${DIRECTUS_INSTANCE}/auth/login`, {
        email,
        password,
      });

      if (response.data && response.data.data.access_token) {
        const token = response.data.data.access_token;
        const jwtPayload = JSON.parse(atob(token.split('.')[1]));
        const tokenExpiry = jwtPayload.exp;

        this.setToken(token, tokenExpiry);
        return token;
      }
      throw new Error('Invalid login response');
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  logout() {
    this.removeToken();
    window.dispatchEvent(new CustomEvent('auth:logout'));
  }
}

export const authService = new AuthService();