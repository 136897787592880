import axios from 'axios';
import axiosRetry from 'axios-retry';
import slugify from 'slugify'; 
import { DIRECTUS_INSTANCE } from '../../api'; // Adjust the import path as necessary

// API Endpoints

const DIRECTUS_ARTICLES_ENDPOINT = `${DIRECTUS_INSTANCE}/items/articles`;

// Create an Axios instance for Directus API
const directusAPI = axios.create({
  baseURL: DIRECTUS_INSTANCE,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Set up axios-retry to automatically retry requests
axiosRetry(directusAPI, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => error.response?.status === 429 || error.response?.status === 503,
});

// Intercept requests to add Authorization header
directusAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('directus_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

// Generic function to handle errors from API requests
const handleApiError = (error) => {
  if (error.response) {
    console.error('Server responded with an error:', error.response.data);
    console.error('Status code:', error.response.status);
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error setting up request:', error.message);
  }
};

const saveArticleToDirectus = async (articleData, isFinal = false) => {
  try {
    const dataToSave = {
      title: articleData.title || 'Untitled',
      slug: slugify(articleData.title || 'untitled', {
        lower: true,
        strict: true,
        remove: /[*+~.()'"!:@]/g
      }),
      status: isFinal ? 'published' : 'draft',
      fog_index: articleData.fog_index || null,
      keywords: articleData.keywords || [],
      content_type: articleData.content_type || null,
      tone_of_voice: articleData.tone_of_voice || null,
      language: articleData.language || null,
      thread_id: articleData.thread_id || null,
      article_draft: articleData.article_draft || null,
      article_body: articleData.article_body ? JSON.stringify({
        content: articleData.article_body
      }) : null,
      project: articleData.project || null,
    };


    let response;
    if (articleData.id) {
      response = await directusAPI.patch(`/items/articles/${articleData.id}`, dataToSave);
    } else {
      response = await directusAPI.post('/items/articles', dataToSave);
    }

    return response.data;
  } catch (error) {
    if (error.response) {
    }
    throw error;
  }
};

// New function to update article status to 'ongoing'
const updateArticleStatusToOngoing = async (articleId) => {
  try {
    const response = await directusAPI.patch(`${DIRECTUS_ARTICLES_ENDPOINT}/${articleId}`, {
      status: 'ongoing'
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// Export both functions as named exports
export { saveArticleToDirectus, updateArticleStatusToOngoing };