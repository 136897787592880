import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { DIRECTUS_DATASETS_ENDPOINT, DIRECTUS_PROJECTS_ENDPOINT, DIRECTUS_FILES_ENDPOINT, DIRECTUS_INSTANCE } from '../../api';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';

const CreateDataSet = () => {
  const [datasetName, setDatasetName] = useState('');
  const [datasetType, setDatasetType] = useState('');
  const [files, setFiles] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [projects, setProjects] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');

  const title = "Create Dataset";
  const description = "";

  const ALLOWED_FILE_TYPES = [
    'text/plain',                                         // .txt files
    'text/csv',                                          // .csv files
    'application/pdf',                                   // .pdf files
    'application/msword',                                // .doc files
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx files
  ];

  useEffect(() => {
    const validateToken = async () => {
      try {
        await axios.get(`${DIRECTUS_INSTANCE}/users/me`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('Token is valid');
      } catch (error) {
        console.error('Token validation failed:', error);
        // Handle invalid token (e.g., redirect to login)
      }
    };
    validateToken();
  }, [token]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const userId = localStorage.getItem('user_id');
        const response = await axios.get(DIRECTUS_PROJECTS_ENDPOINT, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            filter: { owner: userId }
          }
        });
        setProjects(response.data.data);
      } catch (error) {
        setError('Failed to fetch projects. ' + (error.response?.data?.message || error.message));
      }
    };

    fetchProjects();
  }, [token]);

  const handleDrop = (acceptedFiles) => {
    const invalidFiles = acceptedFiles.filter(file => !ALLOWED_FILE_TYPES.includes(file.type));
    
    if (invalidFiles.length > 0) {
      setError('Only text files (TXT, CSV, PDF, DOC, DOCX) are allowed.');
      return;
    }
    
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    setError(''); // Clear any previous errors
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop: handleDrop,
    accept: {
      'text/plain': ['.txt'],
      'text/csv': ['.csv'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    multiple: false
  });

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const handleProjectChange = (event) => {
    setSelectedProjectId(event.target.value);
    if (event.target.value === 'new-project') {
      navigate('/projects/create');
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!datasetName) {
      errors.datasetName = "Dataset name is required";
    }
    if (!selectedProjectId || selectedProjectId === "Select Project") {
      errors.selectedProjectId = "A related project must be selected";
    }
    if (files.length === 0) {
      errors.files = "At least one file must be uploaded";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setError('');

    try {
      // Step 1: Upload the file
      let fileId = null;
      if (files.length > 0) {
        const fileFormData = new FormData();
        fileFormData.append('file', files[0]);
        fileFormData.append('folder', 'a449f44e-926b-4940-9f46-0f5017223089');

        console.log('Uploading file...');

        const fileResponse = await axios.post(DIRECTUS_FILES_ENDPOINT, fileFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('File uploaded:', fileResponse.data);
        fileId = fileResponse.data.data.id;
      }

      // Step 2: Create the dataset
      const datasetData = {
        name: datasetName,
        type: datasetType,
        project: selectedProjectId,
        owner: userId,
        file: fileId ? {
          create: [{ directus_files_id: fileId }],
          delete: []
        } : undefined
      };

      console.log('Creating dataset with data:', datasetData);

      const datasetResponse = await axios.post(DIRECTUS_DATASETS_ENDPOINT, datasetData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('Dataset created:', datasetResponse.data);

      navigate('/datasets');
    } catch (error) {
      console.error('Error:', error.response?.data || error);
      setError('Failed to create dataset: ' + (error.response?.data?.errors?.[0]?.message || error.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="lg:ml-48">
      <div className="max-w-5xl mx-auto">
        <div className="mx-auto">
          <div className="pb-12">
            <form onSubmit={handleSubmit} className="mt-10 space-y-8">
              <div className="sm:flex sm:items-center mt-16">
                <div className="sm:flex-auto">
                  <h1 className="text-3xl font-medium leading-6 text-primary-900">{title}</h1>
                  <p className="mt-4 text-base text-primary-900 opacity-60 max-w-4xl">{description}</p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <button
                    type="submit"
                    className="rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                  >
                    {isLoading ? 'Creating...' : 'Create Dataset'}
                  </button>
                </div>
              </div>
              <div className="mt-8 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 py-8 sm:p-8">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <label htmlFor="dataset-name" className="block text-sm font-medium leading-6 text-gray-900">
                        Dataset Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder="i.e. My Amazing Dataset"
                          id="dataset-name"
                          value={datasetName}
                          onChange={(e) => setDatasetName(e.target.value)}
                          required
                          className="block w-full rounded-md border-0 !py-2.5 text-gray-900 shadow-sm ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {formErrors.datasetName && <span className="text-red-500 text-sm">{formErrors.datasetName}</span>}
                    </div>

                    <div className="sm:col-span-6">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Data Type</label>
                      <select
                        className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                        id="dataset-type"
                        value={datasetType}
                        onChange={(e) => setDatasetType(e.target.value)}
                      >
                        <option value="user_interview_transcript">User Interview Transcript</option>
                        <option value="product_brochure">Product Brochure</option>
                        <option value="blog_post_article">Article or Blogpost Sample</option>
                        <option value="database">Database</option>
                      </select>
                    </div>

                    <div className="sm:col-span-6">
                      <label htmlFor="project-select" className="block text-sm font-medium leading-6 text-gray-900">
                        Related Project
                      </label>
                      <div className="mt-2">
                        <select 
                          required 
                          name="project" 
                          id="project-select" 
                          onChange={handleProjectChange} 
                          value={selectedProjectId}
                          className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                        >
                          <option>Select Project</option>
                          {projects.map((project) => (
                            <option key={project.id} value={project.id}>{project.project_name}</option>
                          ))}
                          <option value="new-project">Create New Project</option>
                        </select>
                      </div>
                      {formErrors.selectedProjectId && <span className="text-red-500 text-sm">{formErrors.selectedProjectId}</span>}
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="files" className="block text-sm font-medium leading-6 text-gray-900">
                        Drag & Drop Files
                      </label>
                      <div
                        {...getRootProps()}
                        className={`mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 w-full ${
                          isDragActive ? 'bg-primary-50' : ''
                        }`}
                      >
                        <div className="text-center">
                          <CloudArrowUpIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                          <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer rounded-md bg-white font-semibold text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2 hover:text-primary-500"
                            >
                              <span>Upload a file</span>
                              <input {...getInputProps()} />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs leading-5 text-gray-600">TXT, CSV, PDF, DOC, or DOCX files up to 10MB</p>
                        </div>
                      </div>
                      <div className="mt-2">
                        {files.length > 0 && (
                          <div>
                            <h4 className="text-sm font-medium text-gray-900">Selected Files</h4>
                            <ul className="mt-1 divide-y divide-gray-100 rounded-md border border-gray-200">
                              {files.map((file, index) => (
                                <li key={index} className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                  <div className="flex w-0 flex-1 items-center">
                                    <span className="ml-2 w-0 flex-1 truncate">{file.name}</span>
                                  </div>
                                  <div className="ml-4 flex-shrink-0">
                                    <button
                                      type="button"
                                      className="font-medium text-primary-600 hover:text-primary-500"
                                      onClick={() => handleRemoveFile(file)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {formErrors.files && <span className="text-red-500 text-sm">{formErrors.files}</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
              {isLoading && <div className="mt-4 text-sm text-gray-500">Creating dataset...</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDataSet;