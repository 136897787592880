export const generateEcommerceProductDescriptionPrompt = (productInfo, keywords, toneOfVoice, complexity, language) => {
   return `
     As an e-commerce copywriter, create a concise and compelling product description for a product page. Follow these guidelines:

     1. Structure:
        - Title: Create a short, attention-grabbing title for the product.
        - Short Product Overview: Write a brief introduction of the product (100-250 words).

     2. Content:
        - Highlight 3-5 key features or benefits that make this product stand out.
        - Address the primary customer pain points this product solves.
        - Include any crucial specifications (e.g., size, material, compatibility).

     3. Style:
        - Tone: ${toneOfVoice}
        - Complexity: Aim for a Fog Index of ${complexity} in ${language}
        - Use sensory and benefit-driven language to appeal to shoppers.

     4. SEO:
        - Naturally incorporate the main keyword: "${keywords[0]}" and at least one secondary keyword.
        - Optimize for readability and quick scanning by online shoppers.

     5. Format:
        Use the following JSON structure for your response:
        {
          "sections": [
            {
              "header": "Product Title",
              "paragraph": "Your attention-grabbing title here"
            },
            {
              "header": "Short Product Overview",
              "paragraph": "Your brief product introduction here (100-250 words)"
            }
          ]
        }

     Write the product description now, focusing on creating compelling, conversion-oriented content for an e-commerce product page.
   `;
 };