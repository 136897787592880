import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

const parseQualityReportToHTML = (reportArray) => {
  if (!Array.isArray(reportArray) || reportArray.length === 0) {
    return <p>Ensure that your article is well formatted and SEO friendly.</p>;
  }

  const report = reportArray[0].quality_control_report;

  const formatValue = (key, value, parentKey, parentValue) => {
    if (key === 'counts' && typeof value === 'object') {
      return (
        <div className="">
          {Object.entries(value).map(([k, v]) => (
            <span className="inline-block bg-neutral-50 rounded-full p-2 px-3 mr-1 mb-2" key={k}>{`${k}: ${v}`}</span>
          ))}
        </div>
      );
    }
    // Remove the score formatting from here
    return value;
  };

  return (
    <div>
      {Object.entries(report).map(([key, value]) => {
        if (key === 'Overall Score') return null; // Skip Overall Score as it's displayed in the chart
        return (
          <div key={key} className="mb-4 border-b border-gray-300 pb-2">
            <h3 className="font-medium text-base flex items-center justify-between">
              <div className="flex items-center">
                {key}
                {value.description && (
                  <div className="relative group">
                    <QuestionMarkCircleIcon className="ml-2 h-5 w-5 text-gray-400 cursor-pointer" />
                    <div className="absolute bottom-full mb-2 hidden w-64 text-sm leading-5 text-white bg-gray-800/90 backdrop-blur-sm border border-gray-700 rounded-md shadow-lg group-hover:block transform -translate-x-1/2 left-1/2">
                      <div className="p-3 space-y-2">
                        <div>{value.description}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {value.score !== undefined && (
                <span className={`font-semibold ${getColorClass(value.score, value.maxScore)} px-2 py-1 rounded`}>
                  {`${value.score}/${value.maxScore}`}
                </span>
              )}
            </h3>
            {Object.entries(value).map(([subkey, subvalue]) => (
              subkey !== 'description' && subkey !== 'maxScore' && subkey !== 'isIdeal' && subkey !== 'isH1Correct' && subkey !== 'score' && (
                <p className="text-sm opacity-80" key={subkey}>
                  <span className="font-medium">{subkey.replace(/([A-Z])/g, ' $1').trim()}: </span>
                  {formatValue(subkey, subvalue, key, value)}
                </p>
              )
            ))}
          </div>
        );
      })}
    </div>
  );
};

const getColorClass = (score, maxScore) => {
  const percentage = (score / maxScore) * 100;
  if (percentage >= 80) {
    return 'bg-primary-100 text-primary-500';
  } else if (percentage >= 50) {
    return 'bg-yellow-50 text-yellow-600';
  } else {
    return 'bg-red-100 text-red-500';
  }
};

export default parseQualityReportToHTML;