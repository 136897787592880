import React, { useState, useEffect, Fragment, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { DIRECTUS_PROJECTS_ENDPOINT, ITEMS_PER_PAGE } from '../../api';
import ContentHeader from '../Atoms/contentHeader';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon, PlusIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import createProjectIcon from '../../img/create-project.svg';

const statuses = {
  published: 'text-green-700 bg-green-50 ring-green-600/20',
  draft: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// EmptyState component
const EmptyState = ({ onPrimaryClick }) => (
  <div className="flex flex-col items-center justify-center h-full text-center py-24">
    <img 
      src={createProjectIcon} 
      alt="Create Project" 
      className="mx-auto h-32 w-32 text-primary-400"
    />
    <h1 className=" text-3xl font-medium text-gray-900 mt-6">Let's start with new project</h1>
    <p className="mt-1 text-base text-gray-800 opacity-80">Get started by creating a new project to organize your content.</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onPrimaryClick}
        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
      >
        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        New Project
      </button>
    </div>
  </div>
);

// Project skeleton loader component
const ProjectSkeleton = () => (
  <tr className="animate-pulse">
    <td className="px-0 py-6 whitespace-nowrap">
      <div className="h-8 bg-gray-200 rounded w-3/4"></div>
    </td>
    <td className="px-6 py-6 whitespace-nowrap">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="px-6 py-6 whitespace-nowrap">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="px-6 py-6 whitespace-nowrap">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="px-6 py-6 whitespace-nowrap">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
  </tr>
);

function ProjectsTable() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');

  const title = 'Projects';
  const description = 'This table provides a quick snapshot of each project\'s details, allowing you to easily manage and organize your content. Navigate through the list to view specific information or to make updates to existing projects.';
  const primaryAction = 'Create New Project';

  const primaryClick = useCallback(() => navigate('/projects/create-project'), [navigate]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(DIRECTUS_PROJECTS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { owner: userId },
            fields: ['*', 'user_created.first_name', 'user_created.last_name', 'owner_details.first_name', 'owner_details.last_name'],
            limit: ITEMS_PER_PAGE,
            page: currentPage
          }
        });
        
        const processedProjects = response.data.data
          .map(project => ({
            ...project,
            owner: project.user_created?.first_name 
              ? `${project.user_created.first_name} ${project.user_created.last_name}`
              : project.owner_details?.first_name 
                ? `${project.owner_details.first_name} ${project.owner_details.last_name}`
                : 'Unknown'
          }))
          .sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

        setProjects(processedProjects);
        setTotalPages(Math.ceil(response.data.meta.total_count / ITEMS_PER_PAGE));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, token, currentPage]);

  // Add this helper function
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  // Add handleRowClick callback
  const handleRowClick = useCallback((project) => {
    navigate(`/projects/${project.id}`);
  }, [navigate]);

  // Add this component for table rows
  const ProjectRow = React.memo(({ project, onRowClick }) => (
    <tr 
      className="hover:bg-neutral-50 cursor-pointer"
      onClick={() => onRowClick(project)}
    >
      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div className="ml-4">
            <div className="font-medium text-gray-900">{project.project_name}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        <span className={classNames(
          statuses[project.status] || 'text-gray-600 bg-gray-50 ring-gray-500/10',
          'whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
        )}>
          {project.status || 'N/A'}
        </span>
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {project.owner}
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {formatDate(project.date_created)}
      </td>
      <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
        <Menu as="div" className="relative flex-none">
          <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
            <span className="sr-only">Open options</span>
            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              <Menu.Item>
                {({ focus }) => (
                  <a
                    href={`/projects/${project.id}`}
                    className={classNames(
                      focus ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    )}
                  >
                    Edit<span className="sr-only">, {project.project_name}</span>
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ focus }) => (
                  <a
                    href="/projects/move"
                    className={classNames(
                      focus ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    )}
                  >
                    Move<span className="sr-only">, {project.project_name}</span>
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ focus }) => (
                  <a
                    href="/projects/delete"
                    className={classNames(
                      focus ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    )}
                  >
                    Delete<span className="sr-only">, {project.project_name}</span>
                  </a>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </td>
    </tr>
  ));

  // Add pagination handler
  const handlePageChange = useCallback((newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  }, [totalPages]);

  // Update the table structure in the return statement
  return (
    <div className="lg:pl-24">
      <div className="max-w-7xl mx-auto">
        <ContentHeader
          title={title}
          description={description}
          primaryAction={primaryAction}
          onPrimaryAction={primaryClick}
        />

        <div className="divide-y divide-gray-100 bg-white rounded border border-neutral-200 px-6 mt-8">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {loading ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody>
                    {Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => (
                      <ProjectSkeleton key={index} />
                    ))}
                  </tbody>
                </table>
              ) : projects.length === 0 ? (
                <EmptyState onPrimaryClick={primaryClick} />
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Project Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Status
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Owner
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Creation Date
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {projects.map((project) => (
                      <ProjectRow 
                        key={project.id} 
                        project={project} 
                        onRowClick={handleRowClick}
                      />
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        {!loading && projects.length > 0 && (
          <nav className="border-t border-gray-200 px-8 py-3 flex items-center justify-between sm:px-6">
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(currentPage - 1) * ITEMS_PER_PAGE + 1}</span> to{' '}
                  <span className="font-medium">{Math.min(currentPage * ITEMS_PER_PAGE, projects.length)}</span> of{' '}
                  <span className="font-medium">{totalPages * ITEMS_PER_PAGE}</span> results
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <button 
                    onClick={() => handlePageChange(currentPage - 1)} 
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Previous</span>
                  </button>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i}
                      aria-current={currentPage === i + 1 ? 'page' : undefined}
                      onClick={() => handlePageChange(i + 1)}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                        currentPage === i + 1 ? 'z-10 bg-primary-50 text-primary-600' : 'text-gray-500 hover:bg-gray-50'
                      }`}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button 
                    onClick={() => handlePageChange(currentPage + 1)} 
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Next</span>
                  </button>
                </nav>
              </div>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}

export default ProjectsTable;