export const generateBlogPostPrompt = (articleDraft, keywords, toneOfVoice, complexity, language) => {
   return `
    As a professional content writer, create a comprehensive blog post:

    FORMAT REQUIREMENTS (STRICT):
    - Use pure markdown format
    - Do NOT output JSON structure
    - Do NOT wrap content in sections array
    - Use # for H1 title
    - Use ## for H2 sections
    - Use ### for H3 subsections
    - Use regular paragraphs with line breaks
    - Use - for bullet points
    - Use 1. for numbered lists
    - Article should be at least 1000 words (!)

    1. Structure:
       - Follow the provided outline, expanding each point in length as in outline (important!).
       - Use H2 for main sections, H3 for subsections.
       - Include multiple paragraphs per section.

    2. Content:
       - Provide detailed explanations and examples.
       - Address potential reader questions.
       - Use real data when available; avoid assumptions.
       - Avoid too many bulletpoints - keep all in Prose.

    3. Keywords:
       - User ALL Main keywords: ${keywords.join(', ')}
       - Include Keywords in H1, H2, and throughout the text (3% density).
       - Make keywords bold (!).

    4. Components:
       - Title (H1): Engaging, includes primary keyword.
       - Introduction (150-200 words): Hook and overview.
       - Body: Fully developed sections with logical flow.
       - Conclusion (100-150 words): Summary and call-to-action.

    5. Style:
       - Ensure tone: ${toneOfVoice}
       - Ensure complexity: Fog Index of ${complexity}
       - Conversational and engaging.

    Use rhetorical questions, analogies, and a mix of informational and entertaining content.
   `;
};