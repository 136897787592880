import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DIRECTUS_DATASETS_ENDPOINT } from '../../api';
import { PlusIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { format } from 'date-fns';
import ContentHeader from '../Atoms/contentHeader';
import provideDataIcon from '../../img/provide-data.svg';

const title = 'Datasets';
const description = 'This table provides a quick snapshot of each dataset\'s details, allowing you to easily manage and review them. Navigate through the list to view specific information or to make updates to existing datasets.';
const primaryAction = 'Create New Dataset';

const EmptyState = ({ onPrimaryClick }) => (
  <div className="flex flex-col items-center justify-center h-full text-center py-24">
    <img 
      src={provideDataIcon} 
      alt="Create Dataset" 
      className="mx-auto h-32 w-32 text-orange-400"
    />
    <h1 className="text-3xl font-medium text-gray-900 mt-6">Let's add some data</h1>
    <p className="mt-1 text-base text-gray-800 opacity-80">Get started by creating a new dataset to train AI with your content.</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onPrimaryClick}
        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
      >
        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        New Dataset
      </button>
    </div>
  </div>
);

const SkeletonRow = React.memo(() => (
  <tr className="animate-pulse">
    <td className="px-0 py-6 whitespace-nowrap">
      <div className="h-8 bg-gray-200 rounded w-3/4"></div>
    </td>
    <td className="px-6 py-6 whitespace-nowrap">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="px-6 py-6 whitespace-nowrap">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="px-6 py-6 whitespace-nowrap">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="px-6 py-6 whitespace-nowrap">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
  </tr>
));

function DatasetsTable() {
  const [datasets, setDatasets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');
  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(DIRECTUS_DATASETS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { user_created: { _eq: userId } },
            fields: ['id', 'name', 'type', 'file.id', 'date_created', 'date_updated', 'project.name'],
            sort: ['-date_created'],
            page: currentPage,
            limit: ITEMS_PER_PAGE
          }
        });
        setDatasets(response.data.data);
        setTotalPages(Math.ceil(response.data.meta.total_count / ITEMS_PER_PAGE));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching datasets:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, token, currentPage]);

  const getLastUpdateDate = (dataset) => {
    const date = dataset.date_updated || dataset.date_created;
    return format(new Date(date), 'MMM d, yyyy');
  };

  const getNumberOfFiles = (dataset) => {
    return Array.isArray(dataset.file) ? dataset.file.length : 0;
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleDatasetClick = (datasetId) => {
    navigate(`/datasets/${datasetId}`);
  };

  const primaryClick = useCallback(() => navigate('/datasets/create-dataset'), [navigate]);

  return (
    <div className="lg:pl-24">
      <div className="max-w-7xl mx-auto">
        <ContentHeader
          title={title}
          description={description}
          primaryAction={primaryAction}
          onPrimaryAction={primaryClick}
        />

        <div className="divide-y divide-gray-100 bg-white mt-8">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {loading ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody>
                    {Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => (
                      <SkeletonRow key={index} />
                    ))}
                  </tbody>
                </table>
              ) : datasets.length === 0 ? (
                <EmptyState onPrimaryClick={primaryClick} />
              ) : (
                <>
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Project</th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Number of Files</th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last Update</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {datasets.map((dataset) => (
                          <tr 
                            key={dataset.id} 
                            onClick={() => handleDatasetClick(dataset.id)} 
                            className="cursor-pointer hover:bg-gray-100"
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{dataset.name}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{dataset.project?.name || 'N/A'}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{dataset.type || 'N/A'}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getNumberOfFiles(dataset)}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{getLastUpdateDate(dataset)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {!loading && datasets.length > 0 && (
          <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-6">
            <div className="-mt-px flex w-0 flex-1">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                <ChevronLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                Previous
              </button>
            </div>
            <div className="hidden md:-mt-px md:flex">
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  onClick={() => handlePageChange(i + 1)}
                  className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                    currentPage === i + 1
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }`}
                >
                  {i + 1}
                </button>
              ))}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Next
                <ChevronRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </button>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}

export default DatasetsTable;