import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { DIRECTUS_INSTANCE } from './api'; // Adjust the import path as necessary
import axios from 'axios';
import Header from './components/Header/header';
/* Pinecone */ 
import Pinecone from './components/Pinecone/DataStorageManager';
/* Assistant */ 
import AssistantAI from './components/Assistant/assistant_ai';
import ChatSessionsTable from './components/Assistant/chatSessions'; // Import the new component
import SingleChat from './components/Assistant/SingleChat'; // Import the new component
import { authService } from './services/authService';

/* General */ 
import Sidebar from './components/Sidebar/sidebar';
import Dashboard from './components/Dashboard/dashboard';
import UserProfile from './components/UserProfile/profile';
import PricingTable from './components/PricingTable/pricingTable';


/* Onboarding */ 
import FirstOnboarding from './components/Onboarding/onboarding';


/* Registration */
import SignIn from './components/Registration/signin';
import SignUp from './components/Registration/signup'; 


/* Projects */ 
import ProjectsTable from './components/Projects/projectsTable';
import ProjectDetails from './components/Projects/projectDetails';
import CreateProject from './components/Projects/createProject';



/* Dataset */ 
import Datasets from './components/Datasets/datasets';
import CreateDatasets from './components/Datasets/createDataSet';
import DatasetSingle from './components/Datasets/datasetSingle';




/* Articles */ 
import ArticlesTable from './components/ArticlesGenerator/articlesTable';
import Articles from './components/ArticlesGenerator/GenerateDraft/CreateArticleDraft';
import ArticleDraft from './components/ArticlesGenerator/SingleArticle/Article';


import {
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UsersIcon,
  Bars3Icon,
  CalculatorIcon,
  DocumentTextIcon,
  PresentationChartLineIcon,
  RocketLaunchIcon,
  ChatBubbleBottomCenterIcon,
  TableCellsIcon
} from '@heroicons/react/24/outline'

const teams = [
  { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('directus_token') || '');
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [trialEnded, setTrialEnded] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      setIsAuthenticated(!!token);
      const tokenExpiryFromStorage = localStorage.getItem('directus_token_expiry');
      const currentTime = Math.floor(new Date().getTime() / 1000);

      if (!token && !['/signin', '/signup'].includes(location.pathname)) {
        navigate('/signin');
      } else if (token) {
        try {
          const response = await axios.get(`${DIRECTUS_INSTANCE}/users/me`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.data && response.data.data) {
            localStorage.setItem('user_id', response.data.data.id);
            
            // Check trial status
            const trialEnds = response.data.data.trial_ends ? new Date(response.data.data.trial_ends) : null;
            const isTrialEnded = trialEnds ? new Date() > trialEnds : false;
            setTrialEnded(isTrialEnded);

            // If trial ended and not on pricing or profile page, redirect to pricing
            if (isTrialEnded && 
                !['/pricing', '/profile', '/'].includes(location.pathname)) {
              navigate('/pricing');
              return;
            }

            // Rest of your existing authentication logic
            const onboardingStatus = response.data.data.onboarding_completed;
            setOnboardingCompleted(onboardingStatus);

            if (!onboardingStatus && location.pathname !== '/onboarding') {
              navigate('/onboarding');
            }
          }
        } catch (error) {
          console.error('Error fetching user details:', error);
          handleSignOut();
        }
      }
    };

    checkAuthentication();
    const tokenValidationInterval = setInterval(checkAuthentication, 60 * 1000);
    return () => clearInterval(tokenValidationInterval);
  }, [token, location.pathname]);

  const handleSignIn = async (newToken) => {
    localStorage.setItem('directus_token', newToken);  // Save the token first
    // Decode token to get expiry time. The snippet below assumes the JWT token is structured as three Base64Url strings separated by "."
    const jwtPayload = JSON.parse(atob(newToken.split('.')[1]));
    const tokenExpiry = jwtPayload.exp; //timestamp when the token will expire 

    localStorage.setItem("directus_token_expiry", tokenExpiry)

    setToken(newToken);
    setIsAuthenticated(true);

    try {
      // Fetch user details using the token
      const response = await axios.get(`${DIRECTUS_INSTANCE}/users/me`, {
        headers: {
          'Authorization': `Bearer ${newToken}`,
        },
      });

      if (response.data && response.data.data) {
        // Assuming user ID is available as `id` in the user object
        localStorage.setItem('user_id', response.data.data.id);  // Save the user ID

        // Check if the user has completed onboarding
        const onboardingStatus = response.data.data.onboarding_completed; // Adjust this based on your Directus schema
        setOnboardingCompleted(onboardingStatus);

        if (onboardingStatus) {
          navigate('/');  // Navigate to the dashboard after login
        } else {
          navigate('/onboarding');  // Navigate to the onboarding page if not completed
        }
      } else {
        throw new Error('Failed to fetch user details');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      // Handle error, e.g., by logging out the user
      handleSignOut();
    }
  };

  const handleSignOut = () => {
    localStorage.clear();
    setToken('');
    setIsAuthenticated(false);
    navigate('/signin');
  };

  const navigation = [
    { name: 'Dashboard', href: '/', icon: HomeIcon, current: location.pathname === '/' },
    { name: 'Projects', href: '/projects', icon: DocumentDuplicateIcon, current: location.pathname === '/projects' },
    { name: 'Articles', href: '/articles', icon: DocumentTextIcon, current: location.pathname === '/articles' },
    { name: 'Content Assistant', href: '/assistants', icon: ChatBubbleBottomCenterIcon, current: location.pathname === '/assistants' },
    { name: 'Datasets', href: '/datasets', icon: TableCellsIcon, current: location.pathname === '/data-storage' }, 

    /* { name: 'Data Storage (test)', href: '/data-storage', icon: TableCellsIcon, current: location.pathname === '/data-storage' }, */
  ];

  const userNavigation = [
    { name: 'Your profile', href: '/profile' },
    { name: 'Sign out', href: '#', onClick: handleSignOut },
  ];

  // Update navigation items based on the current location
  const updatedNavigation = navigation.map(item => ({
    ...item,
    current: location.pathname === item.href
  }));

  return (
    <div className="bg-white min-h-screen top-0">
      {isAuthenticated ? (
        <>
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            navigation={updatedNavigation}
            classNames={classNames}
            teams={teams}
            Cog6ToothIcon={Cog6ToothIcon}
          />
          <div className="">
            <Header
              setSidebarOpen={setSidebarOpen}
              userNavigation={userNavigation}
              classNames={classNames}
              Bars3Icon={Bars3Icon}
              onSignOut={handleSignOut}
            />
            <main className="">
              <div className="mx-auto w-full pl-4">
                <Routes>
                  {trialEnded ? (
                    <>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/pricing" element={<PricingTable />} />
                      <Route path="/profile" element={<UserProfile />} />
                      <Route path="*" element={<Navigate to="/pricing" replace />} />
                    </>
                  ) : (
                    <>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/onboarding" element={<FirstOnboarding />} />
                      <Route path="/projects" element={<ProjectsTable />} />
                      <Route path="/projects/:id" element={<ProjectDetails />} />
                      <Route path="/projects/create-project" element={<CreateProject />} />
                      <Route path="/profile" element={<UserProfile />} />
                      <Route path="/pricing" element={<PricingTable />} />
                      <Route path="/signup" element={<SignUp />} />
                      <Route path="/datasets" element={<Datasets />} />
                      <Route path="/datasets/:id" element={<DatasetSingle />} />
                      <Route path="/datasets/create-dataset" element={<CreateDatasets />} />
                      <Route path="/articles/create-article" element={<Articles />} />
                      <Route path="/articles/generate-draft" element={<ArticleDraft />} />
                      <Route path="/articles" element={<ArticlesTable />} />
                      <Route path="/articles/:id" element={<ArticleDraft />} />
                      <Route path="/data-storage" element={<Pinecone />} />
                      <Route path="/ai-assistant" element={<AssistantAI />} />
                      <Route path="/assistants" element={<ChatSessionsTable />} />
                      <Route path="/chat/:sessionId" element={<SingleChat />} />
                    </>
                  )}
                </Routes>
              </div>
            </main>
          </div>
        </>
      ) : location.pathname === '/signup' ? (
        <SignUp onSignIn={handleSignIn} />
      ) : (
        <SignIn onSignIn={handleSignIn} />
      )}
    </div>
  );
}