export const generateSocialMediaPostPrompt = (articleContext, keywords, toneOfVoice, complexity, language) => {
  return `
    As a professional social media content creator, create an engaging post:

    FORMAT REQUIREMENTS (STRICT):
    - Output the post in pure text format
    - Include emojis strategically
    - Include hashtags at the end
    - Follow platform character limits
    - Do NOT output JSON structure
    - Do NOT add any additional formatting

    1. Structure:
       - Attention-grabbing opening (hook)
       - Main message/value proposition
       - Call-to-action
       - Relevant hashtags (3-5)
       - Strategic emoji placement (2-4 emojis)

    2. Content:
       - Keep message clear and concise
       - Focus on one main point/benefit
       - Use platform-appropriate voice
       - Include social proof when relevant
       - Create urgency or FOMO when appropriate

    3. Keywords:
       - Use main keywords naturally: ${keywords.join(', ')}
       - Don't overstuff - maintain natural flow
       - Include 1-2 keywords in hashtags

    4. Components:
       - Hook: Engaging opening line
       - Body: Clear, valuable message
       - CTA: Clear action step
       - Hashtags: Relevant to content and brand
       - Emojis: Support message tone

    5. Style:
       - Tone of voice: ${toneOfVoice}
       - Language: ${language}
       - Complexity: Keep simple and direct
       - Be conversational and authentic

    6. Platform Best Practices:
       - LinkedIn: Professional, industry insights (1200-1600 chars)
       - Twitter: Concise, punchy (280 chars)
       - Instagram: Visual, lifestyle-focused (125-150 chars)
       - Facebook: Community-focused (100-250 chars)
       - TikTok
  `;
};