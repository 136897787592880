import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const searchScholarSources = async (articleContext, keywords) => {
  try {
    const response = await axios.get('https://api.searchapi.io/api/v1/search', {
      params: {
        engine: 'google_scholar',
        q: `${articleContext} ${keywords.join(' ')}`,
        api_key: 'qVWv8z1HendaPg74VaoRQmf5',
        num: 10
      },
      timeout: 5000
    });
    return response.data.organic_results || [];
  } catch (err) {
    console.error('Failed to fetch sources:', err.message);
    return [];
  }
};

const SourcesSection = ({ articleContext, keywords }) => {
  const [sources, setSources] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const searchScholarSources = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://api.searchapi.io/api/v1/search', {
        params: {
          engine: 'google_scholar',
          q: `${articleContext} ${keywords.join(' ')}`,
          api_key: 'qVWv8z1HendaPg74VaoRQmf5',
          num: 10
        }
      });

      setSources(response.data.organic_results || []);
    } catch (err) {
      setError('Failed to fetch sources: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (articleContext && keywords.length > 0) {
      searchScholarSources();
    }
  }, [articleContext, keywords]);

  return (
    <section id="sources" className="mb-16">
      <h2 className="text-2xl font-medium mb-1">Sources</h2>
      <p className="text-base text-gray-500 mb-4">
        Select academic sources to support your content
      </p>

      {isLoading && (
        <div className="flex items-center justify-center p-4">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-500"></div>
        </div>
      )}

      {error && (
        <div className="text-red-500 mb-4 p-4 bg-red-50 rounded-lg">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 gap-4">
        {/* Available Sources */}
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">Suggested Sources</h3>
          <div className="space-y-2">
            {sources.map((source) => (
              <div
                key={source.position}
                className="p-3 bg-white border rounded-lg"
              >
                <p className="font-medium">{source.title}</p>
                <p className="text-sm text-gray-500">{source.publication_info?.summary}</p>
                {source.snippet && (
                  <p className="text-sm text-gray-600 mt-1">{source.snippet}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SourcesSection;