import { DIRECTUS_INSTANCE } from '../../api';
import axios from 'axios';

/**
 * Uploads an image file to Directus and returns the response in Editor.js compatible format
 * @param {File} file - The image file to upload
 * @returns {Promise<{success: number, file?: {url: string, id: string}, error?: string}>}
 */
export const uploadImageToDirectus = async (file) => {
  const token = localStorage.getItem('directus_token');
  
  // Create form data for file upload
  const formData = new FormData();
  formData.append('file', file);
  
  // Set folder ID if you want to organize uploads (optional)
  formData.append('folder', 'a449f44e-926b-4940-9f46-0f5017223089');

  try {
    const response = await axios.post(`${DIRECTUS_INSTANCE}/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    });

    // Return success response in Editor.js compatible format
    return {
      success: 1,
      file: {
        url: `${DIRECTUS_INSTANCE}/assets/${response.data.data.id}`,
        id: response.data.data.id,
        name: response.data.data.filename_download,
        size: response.data.data.filesize,
        extension: response.data.data.type
      }
    };
  } catch (error) {
    console.error('Error uploading image:', error);
    return {
      success: 0,
      error: error.response?.data?.errors?.[0]?.message || error.message
    };
  }
};

/**
 * Deletes an image from Directus
 * @param {string} fileId - The ID of the file to delete
 * @returns {Promise<{success: boolean, error?: string}>}
 */
export const deleteImageFromDirectus = async (fileId) => {
  const token = localStorage.getItem('directus_token');

  try {
    await axios.delete(`${DIRECTUS_INSTANCE}/files/${fileId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    return { success: true };
  } catch (error) {
    console.error('Error deleting image:', error);
    return {
      success: false,
      error: error.response?.data?.errors?.[0]?.message || error.message
    };
  }
}; 