import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ITEMS_PER_PAGE, fetchChatSessions, formatDate } from '../../api';
import { ChevronLeftIcon, ChevronRightIcon, ChatBubbleLeftRightIcon, PlusIcon } from '@heroicons/react/20/solid';
import ContentHeader from '../Atoms/contentHeader';
import provideDataIcon from '../../img/provide-data.svg';
const title = 'Content Assistant Sessions';
const description = 'This table provides a quick snapshot of each chat session, allowing you to easily manage and review your conversations.';
const primaryAction = 'Start New Chat';

function ChatSessionsTable({ onSessionSelect }) {
  const [chatSessions, setChatSessions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');

  const primaryClick = useCallback(() => navigate('/chat/new'), [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const data = await fetchChatSessions(token, userId, currentPage);
        const formattedSessions = data.data.map(session => ({
          id: session.id,
          title: session.title,
          userCreated: session.user_created?.first_name && session.user_created?.last_name
            ? `${session.user_created.first_name} ${session.user_created.last_name}`
            : session.user_created?.email || '',
          dateCreated: new Date(session.date_created),
          dateUpdated: session.date_updated ? new Date(session.date_updated) : null,
        }));

        // Sort sessions by date_updated (if exists) or date_created
        formattedSessions.sort((a, b) => {
          const dateA = a.dateUpdated || a.dateCreated;
          const dateB = b.dateUpdated || b.dateCreated;
          return dateB.getTime() - dateA.getTime();
        });

        setChatSessions(formattedSessions);

        const totalCount = data.meta.total_count;
        setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));

        setLoading(false);
      } catch (error) {
        console.error('Error fetching chat sessions:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, token, userId]);

  const EmptyState = React.memo(({ onPrimaryClick }) => (
    <div className="flex flex-col items-center justify-center h-full text-center py-24">
      <img 
        src={provideDataIcon} 
        alt="Assistant" 
        className="mx-auto h-32 w-32 text-orange-400"
      />
      <h1 className="text-3xl font-medium text-gray-900 mt-6">Start a conversation</h1>
      <p className="mt-1 text-base text-gray-800 opacity-80">Get started by creating a new chat session with our AI assistant.</p>
      <div className="mt-6">
        <button
          type="button"
          onClick={onPrimaryClick}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Chat Session
        </button>
      </div>
    </div>
  ));

  const SkeletonRow = React.memo(() => (
    <tr className="animate-pulse">
      <td className="px-0 py-6 whitespace-nowrap">
        <div className="h-8 bg-gray-200 rounded w-3/4"></div>
      </td>
      <td className="px-6 py-6 whitespace-nowrap">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
      </td>
      <td className="px-6 py-6 whitespace-nowrap">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
      </td>
    </tr>
  ));

  const handlePageChange = useCallback((newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  }, [totalPages]);

  const handleRowClick = useCallback((session) => {
    if (onSessionSelect) {
      onSessionSelect(session);
    } else {
      navigate(`/chat/${session.id}`);
    }
  }, [navigate, onSessionSelect]);

  const ChatSessionRow = React.memo(({ session, onRowClick }) => (
    <tr
      className="hover:bg-neutral-50 cursor-pointer"
      onClick={() => onRowClick(session)}
    >
      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div className="ml-4">
            <div className="font-medium text-gray-900">{session.title}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{session.userCreated}</td>
      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
        {formatDate(session.dateUpdated || session.dateCreated)}
        {session.dateUpdated && (
          <span className="text-xs text-gray-400 ml-1">(Updated)</span>
        )}
      </td>
    </tr>
  ));

  return (
    <div className="lg:pl-24">
      <div className="max-w-7xl mx-auto">
        <ContentHeader
          title={title}
          description={description}
          primaryAction={primaryAction}
          onPrimaryAction={primaryClick}
        />
        <div className="divide-y divide-gray-100 bg-white rounded border border-neutral-200 px-6 mt-8">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {loading ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody>
                    {Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => (
                      <SkeletonRow key={index} />
                    ))}
                  </tbody>
                </table>
              ) : chatSessions.length === 0 ? (
                <EmptyState onPrimaryClick={primaryClick} />
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Title
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <span className="hidden md:inline"></span>
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Last Updated
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {chatSessions.map((session) => (
                      <ChatSessionRow key={session.id} session={session} onRowClick={handleRowClick} />
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        {!loading && chatSessions.length > 0 && (
          <nav className="border-t border-gray-200 px-8 py-3 flex items-center justify-between sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="..."
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="..."
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(currentPage - 1) * ITEMS_PER_PAGE + 1}</span> to <span className="font-medium">{Math.min(currentPage * ITEMS_PER_PAGE, chatSessions.length)}</span> of{' '}
                  <span className="font-medium">{totalPages * ITEMS_PER_PAGE}</span> results
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <button onClick={() => handlePageChange(currentPage - 1)} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Previous</span>
                  </button>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i}
                      aria-current={currentPage === i + 1 ? 'page' : undefined}
                      onClick={() => handlePageChange(i + 1)}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${currentPage === i + 1 ? 'z-10 bg-primary-500 text-primary-500' : 'text-gray-500 hover:bg-primary-50'}`}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button onClick={() => handlePageChange(currentPage + 1)} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Next</span>
                  </button>
                </nav>
              </div>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}

export default ChatSessionsTable;