import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DIRECTUS_DATASETS_ENDPOINT, DIRECTUS_FILES_ENDPOINT } from '../../api';
import { format } from 'date-fns';
import { TrashIcon, ArrowUpTrayIcon, CheckCircleIcon, ArrowDownTrayIcon, EyeIcon } from '@heroicons/react/20/solid';
import TopBar from '../TopBar/topBar';

function DatasetSingle() {
  const [dataset, setDataset] = useState(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const [vectorStoreId, setVectorStoreId] = useState(null);
  const [isCreatingVectorStore, setIsCreatingVectorStore] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({});

  useEffect(() => {
    const fetchDataset = async () => {
      try {
        const response = await axios.get(`${DIRECTUS_DATASETS_ENDPOINT}/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            fields: ['*', 'project.name', 'file.*']
          }
        });
        console.log('Dataset response:', response.data.data);
        setDataset(response.data.data);

        // Handle files data
        if (Array.isArray(response.data.data.file)) {
          const filePromises = response.data.data.file.map(async (fileRef) => {
            const fileResponse = await axios.get(`${DIRECTUS_FILES_ENDPOINT}/${fileRef.directus_files_id}`, {
              headers: { Authorization: `Bearer ${token}` }
            });
            return fileResponse.data.data;
          });

          const fileDetails = await Promise.all(filePromises);
          setFiles(fileDetails);
        } else if (response.data.data.file) {
          const fileResponse = await axios.get(`${DIRECTUS_FILES_ENDPOINT}/${response.data.data.file.directus_files_id}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setFiles([fileResponse.data.data]);
        } else {
          setFiles([]);
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching dataset:', err);
        setError('Failed to fetch dataset');
        setLoading(false);
      }
    };

    fetchDataset();
  }, [id, token]);

  const handleDeleteFile = async (fileId) => {
    if (window.confirm('Are you sure you want to delete this file?')) {
      try {
        await axios.delete(`${DIRECTUS_FILES_ENDPOINT}/${fileId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setFiles(files.filter(file => file.id !== fileId));
      } catch (err) {
        console.error('Error deleting file:', err);
        setError('Failed to delete file');
      }
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const formatDateUpdated = (date) => {
    if (!date) return 'No update date provided';
    const now = new Date();
    const updatedDate = new Date(date);
    const diffInSeconds = (now.getTime() - updatedDate.getTime()) / 1000;
    if (diffInSeconds < 60) return `${Math.floor(diffInSeconds)} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} days ago`;
    return format(updatedDate, 'dd MMM yyyy');
  };

  const goBack = () => {
    navigate('/datasets');
  };

  const handleCreateVectorStore = async () => {
    setIsCreatingVectorStore(true);
    try {
      // TODO: Implement API call to create vector store and upload files
      const response = await axios.post(`${DIRECTUS_DATASETS_ENDPOINT}/${id}/vector-store`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setVectorStoreId(response.data.vector_store_id);
      setUploadStatus(response.data.upload_status);
    } catch (err) {
      console.error('Error creating vector store:', err);
      setError('Failed to create vector store');
    } finally {
      setIsCreatingVectorStore(false);
    }
  };

  const handleUploadFile = async (fileId) => {
    try {
      // TODO: Implement API call to upload single file to vector store
      const response = await axios.post(`${DIRECTUS_DATASETS_ENDPOINT}/${id}/upload-file/${fileId}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUploadStatus(prevStatus => ({
        ...prevStatus,
        [fileId]: response.data.status
      }));
    } catch (err) {
      console.error('Error uploading file:', err);
      setError(`Failed to upload file ${fileId}`);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!dataset) return <div>Dataset not found</div>;

  console.log('Files:', files);

  return (
    <div className="lg:pl-24">
      <TopBar
        onGoBack={goBack}
        formatDateUpdated={formatDateUpdated}
        customMiddleContent={
          <span>{dataset.name}</span>
        }
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <h1 className="text-2xl pb-2 font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {dataset.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Project: {dataset.project?.name || 'N/A'} | Type: {dataset.type || 'N/A'}
            </p>
            <p className="mt-2 text-sm text-gray-500">
              Created: {format(new Date(dataset.date_created), 'PPP')} |
              Last Updated: {formatDateUpdated(dataset.date_updated || dataset.date_created)}
            </p>
          </div>
        </div>
        
        {!vectorStoreId && (
          <button
            onClick={handleCreateVectorStore}
            disabled={isCreatingVectorStore}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isCreatingVectorStore ? 'Creating...' : 'Create Vector Store'}
          </button>
        )}
        
        {vectorStoreId && (
          <p className="mt-2 text-sm text-gray-500">
            Vector Store ID: {vectorStoreId}
          </p>
        )}

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        File Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Size
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Format
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {Array.isArray(files) && files.length > 0 ? (
                      files.map((file) => (
                        <tr key={file.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {file.filename_download || file.filename_disk || 'Unnamed File'}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {file.filesize ? formatFileSize(file.filesize) : '0 Bytes'}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {file.type ? file.type.split('/')[1].toUpperCase() : 'Unknown'}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                            <div className="flex items-center space-x-4">
                              {/* Preview button - only for supported formats */}
                              {file.type && (file.type.startsWith('image/') || file.type === 'application/pdf') && (
                                <button
                                  onClick={() => window.open(`${DIRECTUS_FILES_ENDPOINT}/${file.id}?access_token=${token}`, '_blank')}
                                  className="text-indigo-600 hover:text-indigo-900"
                                  title="Preview file"
                                >
                                  <EyeIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                              )}
                              
                              {/* Download button */}
                              <a
                                href={`${DIRECTUS_FILES_ENDPOINT}/${file.id}?access_token=${token}`}
                                download={file.filename_download}
                                className="text-indigo-600 hover:text-indigo-900"
                                title="Download file"
                              >
                                <ArrowDownTrayIcon className="h-5 w-5" aria-hidden="true" />
                              </a>

                              {/* Delete button */}
                              <button
                                onClick={() => handleDeleteFile(file.id)}
                                className="text-red-600 hover:text-red-900"
                                title="Delete file"
                              >
                                <TrashIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="px-6 py-4 text-center text-sm text-gray-500">
                          No files available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatasetSingle;